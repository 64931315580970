/* eslint-disable class-methods-use-this */
import { logInfo, logError } from '../../../utils/logger';
import { getPublicIPAddress } from '../../../utils/trickleICE';
import StreamManager from '../../StreamManager';

// Offer options
const OFFER_OPTIONS: RTCOfferOptions = {
    offerToReceiveAudio: true,
    offerToReceiveVideo: true,
};

// Webcam stream parameters
const HD_WIDTH = 1280;
const HD_HEIGHT = 720;
const FHD_WIDTH = 1920;
const FHD_HEIGHT = 1080;
const THIRTY_FPS = 30;
const VIDEO_ELEMENT_ID = 'webcam-stream';

interface IceServer {
    urls: string[];
    username?: string;
    credential?: string;
}

const WATCH_DOG_TIMER = 45000;

export default class NvWebRTCOutboundStream {
    private degradationPreference: string | null = null;
    private mediaStream: MediaStream | null = null;
    private outboundPeerId: string;
    private outboundPeerConnection: RTCPeerConnection | null = null;
    private outboundEarlyCandidates: RTCIceCandidate[] = [];
    private publicIPAddress: string | null = null;
    private streamManager: StreamManager;

    private connectionWatchDog: NodeJS.Timeout | null = null;
    private isConnected: boolean = false;

    constructor(streamManager: StreamManager, outboundPeerId: string) {
        this.streamManager = streamManager;
        this.outboundPeerId = outboundPeerId;
        this.initiateWebrtcConnection();
        this.startWatchDog();
    }

    private startWatchDog() {
        this.connectionWatchDog = setTimeout(() => {
            if (this.isConnected == false) {
                this.streamManager.handleAppCleanup();
                this.streamManager.handleInboundStreamError();
            }
        }, WATCH_DOG_TIMER);
    }

    async handleWebSocketMessage(msg: string): Promise<void> {
        try {
            const jsonData = JSON.parse(msg);
            if (
                typeof jsonData === 'object' &&
                Object.prototype.hasOwnProperty.call(jsonData, 'apiKey')
            ) {
                switch (jsonData.apiKey) {
                    case 'api/v1/streambridge/ping':
                        // ignore the ping message
                        break;
                    case 'api/v1/setAnswer':
                        if (jsonData.peerId === this.outboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Outbound Stream ----> ',
                                'Received SDP offer for Outbound connection'
                            );
                            this.setRemoteDescription(jsonData.data);
                        }
                        break;
                    case 'api/v1/iceCandidate':
                        if (jsonData.peerId === this.outboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Outbound Stream ----> ',
                                'Received ICE candidates for Outbound connection'
                            );
                            this.onReceiveCandidate(jsonData.data);
                        }
                        break;
                    case 'api/v1/iceServers':
                        if (jsonData.peerId === this.outboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Outbound Stream ----> ',
                                'Received ICE servers for Outbound connection'
                            );
                            if (jsonData.data.iceServers) {
                                logInfo(
                                    this.streamManager,
                                    'Outbound Stream ----> ',
                                    'ICE servers list: ',
                                    jsonData.data.iceServers
                                );
                                logInfo(
                                    this.streamManager,
                                    'Outbound Stream ----> ',
                                    'Getting public IP address'
                                );
                                this.publicIPAddress = await getPublicIPAddress(
                                    this.streamManager,
                                    jsonData.data
                                );
                                logInfo(
                                    this.streamManager,
                                    'Outbound Stream ----> ',
                                    'Public IP address',
                                    this.publicIPAddress
                                );
                                this.createRTCPeerConnectionForWebcam(
                                    jsonData.data.iceServers
                                );
                            }
                        }
                        break;
                    case 'api/v1/configuration':
                        if (jsonData.peerId === this.outboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Outbound Stream ----> ',
                                'Received configuration for Outbound connection'
                            );
                            if (
                                jsonData.data.webrtcInVideoDegradationPreference
                            ) {
                                this.degradationPreference =
                                    jsonData.data.webrtcInVideoDegradationPreference;
                                logInfo(
                                    this.streamManager,
                                    'Outbound Stream ----> ',
                                    'degradationPreference',
                                    this.degradationPreference
                                );
                                this.setContentHint(this.mediaStream);
                                this.getIceServers();
                            }
                        }
                        break;
                    default:
                        logError(
                            this.streamManager,
                            'Unknown apiKey:',
                            jsonData.apiKey
                        );
                }
            }
        } catch (error) {
            logError(
                this.streamManager,
                'Failed to handle WebSocket message',
                error
            );
        }
    }

    // Toggle microphone. Returns boolean value true if microphone is enabled
    // and false if microphone is disabled. Returns undefined in case of error
    public toggleMicrophone = (): boolean | undefined => {
        if (this.mediaStream) {
            logInfo(
                this.streamManager,
                'Outbound  Stream ----> ',
                'Toggling microphone'
            );
            try {
                const audoTrack = this.mediaStream.getAudioTracks()[0];
                if (audoTrack) {
                    if (audoTrack.enabled) {
                        audoTrack.enabled = false;
                        logInfo(
                            this.streamManager,
                            'Outbound  Stream ----> ',
                            'microphone disabled'
                        );
                        return false;
                    } else {
                        audoTrack.enabled = true;
                        logInfo(
                            this.streamManager,
                            'Outbound  Stream ----> ',
                            'microphone enabled'
                        );
                        return true;
                    }
                }
            } catch (error) {
                logError(
                    this.streamManager,
                    'Outbound  Stream ----> ',
                    'Failed to toggle microphone',
                    error
                );
            }
        }
        logError(
            this.streamManager,
            'Outbound  Stream ----> ',
            'Media Stream not found'
        );
        return undefined;
    };

    private getVstConfig(): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'Calling getVstConfig'
        );
        const jsonData = {
            apiKey: 'api/v1/streambridge/configuration',
            data: null,
            peerId: this.outboundPeerId,
        };
        const jsonString = JSON.stringify(jsonData);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private addIceCandidate(candidate: RTCIceCandidateInit): void {
        const jsonData = {
            apiKey: 'api/v1/streambridge/iceCandidate',
            peerId: this.outboundPeerId,
            data: candidate,
        };
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'calling /v1/iceCandidate with ',
            jsonData
        );
        const jsonString = JSON.stringify(jsonData);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private onIceCandidate(event: RTCPeerConnectionIceEvent): void {
        if (!event.candidate) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'received null candidate, that means its the last candidate - client is chromium based'
            );
            return;
        }
        if (event.candidate.candidate.length === 0) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'Received empty string for candidate - client is firefox'
            );
            return;
        }
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'received candidate inside onIceCandidate callback: ',
            event.candidate.candidate
        );
        if (event.candidate.type === 'srflx') {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'The STUN server is reachable for this candidate!'
            );
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                `Public IP Address is: ${event.candidate.address}`
            );
        }
        if (event.candidate.type === 'relay') {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'The TURN server is reachable for this candidate!'
            );
        }
        if (
            this.outboundPeerConnection &&
            this.outboundPeerConnection.currentRemoteDescription
        ) {
            this.addIceCandidate(event.candidate);
        } else {
            this.outboundEarlyCandidates.push(event.candidate);
        }
    }

    private onReceiveCandidate(candidates: RTCIceCandidateInit[]): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            `Received candidates from VMS: ${JSON.stringify(candidates)}`
        );
        if (candidates) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'Creating RTCIceCandidate from each received candidate..'
            );
            for (let i = 0; i < candidates.length; i += 1) {
                const candidate = candidates[i];
                logInfo(
                    this.streamManager,
                    'Outbound Stream ----> ',
                    `Adding ICE candidate - ${i} :${JSON.stringify(candidate)}`
                );
                this.outboundPeerConnection
                    ?.addIceCandidate(candidate)
                    .then(() => {
                        logInfo(
                            this.streamManager,
                            'Outbound Stream ----> ',
                            `addIceCandidate OK - ${i}`
                        );
                    })
                    .catch((error) => {
                        logInfo(
                            this.streamManager,
                            'Outbound Stream ----> ',
                            `addIceCandidate error - ${i}`,
                            error
                        );
                    });
            }
        }
    }

    private onIceConnectionStateChange(): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'ice connection state change: ',
            this.outboundPeerConnection?.iceConnectionState || ''
        );
        if (this.outboundPeerConnection?.iceConnectionState === 'new') {
            // Candidates will come async over websocket
        }
        if (this.outboundPeerConnection?.iceConnectionState === 'connected') {
            // Handle connected state
            this.isConnected = true;
            this.streamManager.setOutboundStreamConnectionStatus(true);
        }
        if (
            this.outboundPeerConnection?.iceConnectionState === 'disconnected'
        ) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'ICE connection failed, restart at this point'
            );
            this.streamManager.handleAppCleanup();
            this.streamManager.handleOutboundStreamError();
        }
        if (this.outboundPeerConnection?.iceConnectionState === 'failed') {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'ICE connection failed, restart at this point'
            );
            this.streamManager.handleAppCleanup();
            this.streamManager.handleOutboundStreamError();
        }
    }

    private onIceCandidateError(e: any): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'onIceCandidateError: ',
            e
        );
        if (e.errorCode !== 701) {
            logError(
                this.streamManager,
                `${e.errorText} error code ${e.errorCode} and url ${e.url}`,
                'onIceCandidateError'
            );
        }
        if (e.errorCode === 701) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'error code is 701 that means DNS failed for ipv6, harmless error'
            );
        }
    }

    private onIceGatheringStateChange(): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'gathering state change: ',
            this.outboundPeerConnection?.iceGatheringState || ''
        );
    }

    private onSignalingStateChange(): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'signaling state change: ',
            this.outboundPeerConnection?.signalingState || ''
        );
    }

    private onConnectionStateChange(): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'connection state change: ',
            this.outboundPeerConnection?.connectionState || ''
        );
        if (this.outboundPeerConnection?.connectionState === 'disconnected') {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'Lost peer connection...'
            );
        }
    }

    private setRemoteDescription(
        sessionDescriptionAnswer: RTCSessionDescriptionInit
    ): void {
        if (this.outboundPeerConnection) {
            this.outboundPeerConnection
                .setRemoteDescription(
                    new RTCSessionDescription(sessionDescriptionAnswer)
                )
                .then(() => {
                    this.outboundEarlyCandidates.forEach(
                        this.addIceCandidate.bind(this)
                    );
                })
                .catch((e) => {
                    logError(
                        this.streamManager,
                        'Failed to set remote description',
                        e
                    );
                });
        } else {
            logError(
                this.streamManager,
                'Failed to set remote description, no peer connection found'
            );
        }
    }

    private sendSessionDescriptionToVst(
        sessionDescription: RTCSessionDescriptionInit
    ): void {
        const sessionDescriptionPayload = {
            apiKey: 'api/v1/streambridge/stream/start',
            peerId: this.outboundPeerId,
            data: {
                clientIpAddr: this.publicIPAddress,
                peerId: this.outboundPeerId,
                isClient: true,
                options: { quality: 'auto', rtptransport: 'udp', timeout: 60 },
                sessionDescription,
            },
        };
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'Payload of stream start: ',
            sessionDescriptionPayload
        );
        const jsonString = JSON.stringify(sessionDescriptionPayload);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private createOffer(): void {
        logInfo(this.streamManager, 'Outbound Stream ----> ', 'Creating Offer');
        this.mediaStream
            ?.getTracks()
            .forEach((track) =>
                this.outboundPeerConnection?.addTrack(
                    track,
                    this.mediaStream as MediaStream
                )
            );
        this.outboundPeerConnection
            ?.createOffer(OFFER_OPTIONS)
            .then((sessionDescription) => {
                logInfo(
                    this.streamManager,
                    'Outbound Stream ----> ',
                    'session Description with bitrates',
                    sessionDescription
                );
                this.outboundPeerConnection?.setLocalDescription(
                    sessionDescription
                );
                return sessionDescription;
            })
            .then((sessionDescription) => {
                this.sendSessionDescriptionToVst(sessionDescription);
            })
            .catch((error) => {
                logError(this.streamManager, 'Failed to create offer', error);
            });
    }

    private createRTCPeerConnectionForWebcam(iceServerList: IceServer[]): void {
        const rtcConfiguration: RTCConfiguration = {
            iceServers: iceServerList,
        };
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'createRTCPeerConnectionForWebcam'
        );
        try {
            this.outboundPeerConnection = new RTCPeerConnection(
                rtcConfiguration
            );
            this.outboundPeerConnection.onicecandidate =
                this.onIceCandidate.bind(this);
            this.outboundPeerConnection.oniceconnectionstatechange =
                this.onIceConnectionStateChange.bind(this);
            this.outboundPeerConnection.onicecandidateerror =
                this.onIceCandidateError.bind(this);
            this.outboundPeerConnection.onicegatheringstatechange =
                this.onIceGatheringStateChange.bind(this);
            this.outboundPeerConnection.onsignalingstatechange =
                this.onSignalingStateChange.bind(this);
            this.outboundPeerConnection.onconnectionstatechange =
                this.onConnectionStateChange.bind(this);
        } catch (error) {
            logError(
                this.streamManager,
                'Outbound Stream ----> ',
                `Failed to create RTC peer connection.`,
                error
            );
        }
        this.createOffer();
    }

    private getIceServers(): void {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'Calling getIceServers',
            this.outboundPeerId
        );
        const jsonData = {
            apiKey: 'api/v1/streambridge/iceServers',
            peerId: this.outboundPeerId,
            data: { peerId: this.outboundPeerId },
        };
        const jsonString = JSON.stringify(jsonData);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private setContentHint(stream: MediaStream | null): void {
        if (!stream) return;

        const videoTracks = stream.getVideoTracks();
        const audioTracks = stream.getAudioTracks();

        if (videoTracks.length > 0) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                `Using video device: ${videoTracks[0].label}`
            );
            videoTracks.forEach((track) => {
                if (this.degradationPreference != null) {
                    logInfo(
                        this.streamManager,
                        'Outbound Stream ----> ',
                        'setting degradation preference: ',
                        this.degradationPreference
                    );
                    track.contentHint =
                        this.degradationPreference === 'resolution'
                            ? 'motion'
                            : 'detail';
                } else {
                    logInfo(
                        this.streamManager,
                        'Outbound Stream ----> ',
                        'setting default degradation preference'
                    );
                    track.contentHint = 'detail';
                }
                logInfo(
                    this.streamManager,
                    'Outbound Stream ----> ',
                    'video track: ',
                    track
                );
            });
        }

        if (audioTracks.length > 0) {
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                `Using audio device: ${audioTracks[0].label}`
            );
            audioTracks.forEach((track) => {
                track.contentHint = 'speech';
                logInfo(
                    this.streamManager,
                    'Outbound Stream ----> ',
                    'audio track: ',
                    track
                );
            });
        }

        this.mediaStream = stream;
    }

    public doCleanup() {
        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'Cleanup media streams'
        );
        if (this.connectionWatchDog) {
            clearTimeout(this.connectionWatchDog);
        }
        if (this.mediaStream) {
            this.mediaStream.getTracks().forEach((track) => track.stop());
            this.mediaStream = null;
        }

        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'Cleanup outbound peer connection'
        );
        if (this.outboundPeerConnection) {
            const jsonPayload = {
                apiKey: 'api/v1/streambridge/stream/stop',
                peerId: this.outboundPeerId,
                data: { peerId: this.outboundPeerId },
            };
            const jsonString = JSON.stringify(jsonPayload);
            this.streamManager.sendWebSocketMessage(jsonString);
            this.outboundPeerConnection.close();
            this.outboundPeerConnection = null;
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'Set outbound peer connection to null'
            );
        }

        logInfo(
            this.streamManager,
            'Outbound Stream ----> ',
            'Cleanup video element'
        );
        const webcamVideoElementId =
            this.streamManager.getConfig().webcamVideoElementId;
        if (webcamVideoElementId) {
            const videoElement = document.getElementById(
                webcamVideoElementId
            ) as HTMLVideoElement | null;
            if (videoElement) {
                videoElement.srcObject = null;
                videoElement.load();
            }
        }
        this.outboundEarlyCandidates = [];
        logInfo(this.streamManager, 'Outbound Stream ----> ', 'cleanup done');
    }

    private async initiateWebrtcConnection(): Promise<void> {
        try {
            // Initialize an empty constraints object
            const constraints: MediaStreamConstraints = {};

            // Conditionally add audio constraints
            if (this.streamManager.getConfig().enableMicrophone) {
                constraints.audio = { echoCancellation: true };
            }

            // Conditionally add video constraints
            if (this.streamManager.getConfig().enableCamera) {
                constraints.video = {
                    width: { min: HD_WIDTH, ideal: HD_WIDTH, max: FHD_WIDTH },
                    height: {
                        min: HD_HEIGHT,
                        ideal: HD_HEIGHT,
                        max: FHD_HEIGHT,
                    },
                    frameRate: { ideal: THIRTY_FPS, max: THIRTY_FPS },
                };
            }
            logInfo(
                this.streamManager,
                'Outbound Stream ----> ',
                'getting user media with constraints',
                constraints
            );
            const stream = await navigator.mediaDevices.getUserMedia(
                constraints
            );
            this.mediaStream = stream;
            const webcamVideoElement =
                this.streamManager.getConfig().webcamVideoElementId;
            if (webcamVideoElement) {
                const videoElement = document.getElementById(
                    webcamVideoElement
                ) as HTMLVideoElement | null;
                if (videoElement) {
                    videoElement.srcObject = stream;
                    logInfo(
                        this.streamManager,
                        'Outbound Stream ----> ',
                        'MediaStream: ',
                        this.mediaStream
                    );
                }
            }
        } catch (error) {
            if (error instanceof DOMException) {
                switch (error.name) {
                    case 'NotFoundError':
                        logError(
                            this.streamManager,
                            'Error: No camera and/or microphone found.'
                        );
                        break;
                    case 'NotAllowedError':
                        logError(
                            this.streamManager,
                            'Error: Permission to use camera and/or microphone was denied.'
                        );
                        break;
                    case 'NotReadableError':
                        logError(
                            this.streamManager,
                            'Error: Could not access camera and/or microphone. The device may be in use by another application.'
                        );
                        break;
                    case 'OverconstrainedError':
                        logError(
                            this.streamManager,
                            'Error: The requested media settings are not supported by the device.'
                        );
                        break;
                    case 'AbortError':
                        logError(
                            this.streamManager,
                            'Error: The operation was aborted.'
                        );
                        break;
                    case 'SecurityError':
                        logError(
                            this.streamManager,
                            'Error: The operation is insecure or the page is not allowed to access media devices.'
                        );
                        break;
                    default:
                        logError(
                            this.streamManager,
                            `Error accessing media devices: ${error.name}`
                        );
                }
            } else {
                logError(
                    this.streamManager,
                    'An unexpected error occurred while trying to access media devices:',
                    error
                );
            }
            this.streamManager.handleAppCleanup();
            this.streamManager.handleOutboundStreamError();
            return;
        }
        this.getVstConfig();
    }
}
