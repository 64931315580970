import StreamManager from '../classes/StreamManager';

export const logInfo = (streamManager: StreamManager, ...messages: any[]) => {
    if (streamManager.getConfig().enableLogs) {
        console.log(...messages);
    }
};

export const logError = (streamManager: StreamManager, ...messages: any[]) => {
    // Check if logs are enabled
    if (streamManager.getConfig().enableLogs) {
        console.error(...messages);
    }
};
