import StreamManager from '../classes/StreamManager';
import { logInfo, logError } from './logger';

export const getPublicIPAddress = async (
    streamManager: StreamManager,
    iceServerList: any
): Promise<string | null> => {
    if (iceServerList) {
        const iceServerObj = iceServerList;
        const { iceServers } = iceServerObj;

        for (const server of iceServers) {
            let url: string | null = null;
            let credential: string | undefined;
            let username: string | undefined;

            if (server.urls) {
                logInfo(
                    streamManager,
                    'Trickle ICE ----> ',
                    'URL:',
                    server.urls
                );
                url = server.urls;
            }
            if (server.credential) {
                logInfo(
                    streamManager,
                    'Trickle ICE ----> ',
                    'Credential:',
                    server.credential
                );
                credential = server.credential;
            }
            if (server.username) {
                logInfo(
                    streamManager,
                    'Trickle ICE ----> ',
                    'Username:',
                    server.username
                );
                username = server.username;
            }

            const ipAddress = await trickleICE(
                streamManager,
                url,
                credential,
                username
            );
            if (ipAddress !== null) {
                return ipAddress;
            }
        }
    } else {
        throw new Error('No data received from the server');
    }
    return null;
};

const trickleICE = async (
    streamManager: StreamManager,
    url: string | null,
    credential?: string,
    username?: string
): Promise<string | null> => {
    if (!url) {
        logInfo(
            streamManager,
            'Trickle ICE ----> ',
            `No valid TURN or STUN URL found, prefix url with either stun, turn, or turns`
        );
        return null;
    }
    const isStun = url.includes('stun');
    const isTurn = url.includes('turn');
    let iceServers: RTCIceServer[];

    if (isStun) {
        iceServers = [
            {
                urls: url,
            },
        ];
    } else if (isTurn) {
        iceServers = [
            {
                urls: url,
                username,
                credential,
            },
        ];
    } else {
        logInfo(
            streamManager,
            'Trickle ICE ----> ',
            'No valid STUN or TURN server found'
        );
        return null;
    }

    logInfo(streamManager, 'Trickle ICE ----> ', iceServers);
    let pc: RTCPeerConnection | null = null;
    try {
        pc = new RTCPeerConnection({
            iceServers,
        });
    } catch (error) {
        logInfo(streamManager, 'Trickle ICE ----> ', error);
        return null;
    }

    pc.createDataChannel('random-data');
    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);

    return new Promise((resolve) => {
        const timeoutMs = 10000; // Set a timeout of 10 seconds
        const timeoutId = setTimeout(() => {
            logInfo(
                streamManager,
                'Trickle ICE ----> ',
                'Timed out waiting for ICE candidate'
            );
            resolve(null);
            logInfo(
                streamManager,
                'Trickle ICE ----> ',
                'Closing peer connection'
            );
            pc?.close();
        }, timeoutMs);

        pc.onicecandidate = (e) => {
            logInfo(
                streamManager,
                'Trickle ICE ----> ',
                'candidate: ',
                e.candidate
            );
            if (!e.candidate) {
                logInfo(
                    streamManager,
                    'Trickle ICE ----> ',
                    'Received null candidate'
                );
                resolve(null);
                clearTimeout(timeoutId);
                logInfo(
                    streamManager,
                    'Trickle ICE ----> ',
                    'Closing peer connection'
                );
                pc?.close();
            } else if (e.candidate.type === 'srflx') {
                logInfo(
                    streamManager,
                    'Trickle ICE ----> ',
                    'The STUN server is reachable!'
                );
                logInfo(
                    streamManager,
                    'Trickle ICE ----> ',
                    `Public IP Address from trickle ICE: ${e.candidate.address}`
                );
                resolve(e.candidate.address);
                clearTimeout(timeoutId);
                logInfo(
                    streamManager,
                    'Trickle ICE ----> ',
                    'Closing peer connection'
                );
                pc?.close();
            }
        };
        pc.onicecandidateerror = (e) => {
            logError(streamManager, e);
            resolve(null);
            clearTimeout(timeoutId);
            logInfo(
                streamManager,
                'Trickle ICE ----> ',
                'Closing peer connection'
            );
            pc?.close();
        };
    });
};
