// Simple UUID generator, replace with better generator if use-case is for cryptographic purposes
export const generateUUID = (): string =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        (c: string): string => {
            const r: number = (Math.random() * 16) | 0;
            const v: number = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        }
    );

export const rewriteSdp = (
    sdp: { sdp: string },
    maxBitrate: number,
    minBitrate: number,
    startBitrate: number
): { sdp: string } => {
    console.info('Using bitrates: ', maxBitrate, minBitrate, startBitrate);
    const sdpStringFind = 'a=fmtp:(.*) (.*)';
    const sdpStringReplace = `a=fmtp:$1 $2;x-google-max-bitrate=${maxBitrate};x-google-min-bitrate=${minBitrate};x-google-start-bitrate=${startBitrate}`;
    let newSDP = sdp.sdp.toString();
    newSDP = newSDP.replace(new RegExp(sdpStringFind, 'g'), sdpStringReplace);
    sdp.sdp = newSDP;
    console.info('modified SDP: ', sdp);
    return sdp;
};

export const getButtonById = (id: string): HTMLButtonElement | undefined => {
    const button = document.getElementById(id) as HTMLButtonElement | null;
    if (button) {
        return button;
    }
    return undefined;
};
