/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
import { getPublicIPAddress } from '../../../utils/trickleICE';
import { logInfo, logError } from '../../../utils/logger';
import StreamManager from '../../StreamManager';

// Offer options
const OFFER_OPTIONS: RTCOfferOptions = {
    offerToReceiveAudio: true,
    offerToReceiveVideo: true,
};

interface IceServer {
    urls: string[];
    username?: string;
    credential?: string;
}

interface BitrateSettings {
    bitrate_min: number | null;
    bitrate_max: number | null;
    bitrate_start: number | null;
}

const WATCH_DOG_TIMER = 45000;

export default class NvWebRTCInboundStream {
    private mediaStream: MediaStream | null = null;
    private inboundPeerId: string;
    private inboundPeerConnection: RTCPeerConnection | null = null;
    private inboundEarlyCandidates: RTCIceCandidate[] = [];
    private iceServers: IceServer[] | null = null;
    private inboundMinBitrate: number | null = null;
    private inboundMaxBitrate: number | null = null;
    private inboundStartBitrate: number | null = null;
    private publicIPAddress!: string | null;
    private useExternalPeerConnection: boolean = false;
    private streamManager: StreamManager;

    private connectionWatchDog: NodeJS.Timeout | null = null;
    private isConnected: boolean = false;

    constructor(streamManager: StreamManager, inboundPeerId: string) {
        this.streamManager = streamManager;
        this.inboundPeerId = inboundPeerId;
        this.initiateWebrtcConnection();
        this.startWatchDog();
    }

    private startWatchDog() {
        this.connectionWatchDog = setTimeout(() => {
            if (this.isConnected == false) {
                this.streamManager.handleAppCleanup();
                this.streamManager.handleInboundStreamError();
            }
        }, WATCH_DOG_TIMER);
    }

    private addDataListener(): void {
        const videoElement = document.getElementById(
            this.streamManager.getConfig().avatarVideoElementId
        );
        if (videoElement && !videoElement.hasAttribute('data-listener-added')) {
            videoElement.setAttribute('data-listener-added', 'true');
            videoElement.addEventListener('loadeddata', () => {
                logInfo(
                    this.streamManager,
                    'Inbound Stream ----> ',
                    'Data has started coming in. The first frame is loaded.'
                );
                // callback when first frame is received. To be used to show loading GIF
                const callback =
                    this.streamManager.getConfig().firstFrameReceivedCallback;
                if (callback) {
                    callback();
                }
            });
        }
    }

    async handleWebSocketMessage(msg: string): Promise<void> {
        try {
            const jsonData = JSON.parse(msg);
            if (
                typeof jsonData === 'object' &&
                Object.prototype.hasOwnProperty.call(jsonData, 'apiKey')
            ) {
                switch (jsonData.apiKey) {
                    case 'api/v1/streambridge/ping':
                        // ignore the ping message
                        break;
                    case 'api/v1/stream/start':
                        if (jsonData.peerId === this.inboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'Received SDP offer for Inbound connection'
                            );
                            this.handleSDPOffer(jsonData.data);
                        }
                        break;
                    case 'api/v1/setAnswer':
                        // Unreal Engine Case
                        if (
                            Object.prototype.hasOwnProperty.call(
                                jsonData,
                                'wait_for_offer'
                            )
                        ) {
                            logInfo(this.streamManager, 'Waiting for offer...');
                            // perform webrtcCleanup of old connections if exist
                            logInfo(
                                this.streamManager,
                                'Cleanup inbound connections started'
                            );
                            if (this.inboundPeerConnection) {
                                this.inboundPeerConnection.close();
                                this.inboundPeerConnection = null;
                            }
                            this.inboundEarlyCandidates = [];
                            logInfo(
                                this.streamManager,
                                'Cleanup inbound connections success, return'
                            );
                            return;
                        }
                        if (
                            Object.prototype.hasOwnProperty.call(
                                jsonData,
                                'data'
                            ) &&
                            Object.prototype.hasOwnProperty.call(
                                jsonData,
                                'peerId'
                            ) &&
                            jsonData.peerId === this.inboundPeerId
                        ) {
                            // this is response of stream/start call
                            logInfo(
                                this.streamManager,
                                'Inbound  Stream ----> ',
                                'Received SDP answer for Inbound connection'
                            );
                            this.addDataListener();
                            this.setRemoteDescription(jsonData.data);
                        }
                        break;
                    case 'api/v1/iceCandidate':
                        if (jsonData.peerId === this.inboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'Received ICE candidates for Inbound connection'
                            );
                            this.onReceiveCandidate(jsonData.data);
                        }
                        break;
                    case 'api/v1/iceServers':
                        if (jsonData.peerId === this.inboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'Received ICE servers for Inbound connection'
                            );
                            if (jsonData.data.iceServers) {
                                logInfo(
                                    this.streamManager,
                                    'Inbound Stream ----> ',
                                    'ICE servers list: ',
                                    jsonData.data.iceServers
                                );
                                this.iceServers = jsonData.data.iceServers;
                                logInfo(
                                    this.streamManager,
                                    'Inbound Stream ----> ',
                                    'Getting public IP address '
                                );
                                this.publicIPAddress = await getPublicIPAddress(
                                    this.streamManager,
                                    jsonData.data
                                );
                                logInfo(
                                    this.streamManager,
                                    'Inbound Stream ----> ',
                                    'Public IP address is',
                                    this.publicIPAddress
                                );
                                if (this.publicIPAddress) {
                                    this.streamManager.setPublicIPAddress(
                                        this.publicIPAddress
                                    );
                                }
                                this.createRTCPeerConnectionForInboundStream(
                                    jsonData.data.iceServers
                                );
                            }
                        }
                        break;
                    case 'api/v1/configuration':
                        if (jsonData.peerId === this.inboundPeerId) {
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'Received configuration for Inbound connection'
                            );
                            const { bitrate_min, bitrate_max, bitrate_start } =
                                this.getBitrateSettings(jsonData.data);
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'bitrate min: ',
                                bitrate_min
                            );
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'bitrate max: ',
                                bitrate_max
                            );
                            logInfo(
                                this.streamManager,
                                'Inbound Stream ----> ',
                                'bitrate start: ',
                                bitrate_start
                            );
                            this.inboundMinBitrate = bitrate_min;
                            this.inboundMaxBitrate = bitrate_max;
                            this.inboundStartBitrate = bitrate_start;
                            this.useExternalPeerConnection =
                                jsonData?.data?.use_external_peerconnection;
                            this.getIceServers();
                        }
                        break;
                    default:
                        logError(
                            this.streamManager,
                            'Unknown apiKey:',
                            jsonData.apiKey
                        );
                }
            }
        } catch (error) {
            logError(
                this.streamManager,
                'Failed to handle WebSocket message',
                error
            );
        }
    }

    private async handleSDPOffer(sdpOffer: any): Promise<void> {
        if (!sdpOffer || !sdpOffer.sessionDescription || !sdpOffer.streamId) {
            logInfo(this.streamManager, 'Received incomplete data, skipping');
            return;
        }
        this.startPeerConnection(this.iceServers, sdpOffer.sessionDescription);
    }

    private startPeerConnection(
        iceServers: IceServer[] | null,
        extendedOffer: RTCSessionDescriptionInit
    ): void {
        this.createRTCPeerConnectionForInboundStream(iceServers, false);
        this.handleOffer(extendedOffer);
    }

    private onReceiveSingleCandidate(candidate: RTCIceCandidateInit): void {
        if (candidate) {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                `Adding ICE candidate - :${JSON.stringify(candidate)}`
            );
            this.inboundPeerConnection
                ?.addIceCandidate(candidate)
                .then(() => {
                    logInfo(
                        this.streamManager,
                        'Inbound Stream ----> ',
                        `addIceCandidate OK`
                    );
                })
                .catch((error) => {
                    logInfo(
                        this.streamManager,
                        'Inbound Stream ----> ',
                        `addIceCandidate error`,
                        error
                    );
                });
        }
    }

    private handleOffer(extendedOffer: RTCSessionDescriptionInit): void {
        if (this.inboundPeerConnection) {
            logInfo(this.streamManager, 'SDP Offer: ', extendedOffer);
            this.inboundPeerConnection
                .setRemoteDescription(new RTCSessionDescription(extendedOffer))
                .then(() => {
                    logInfo(
                        this.streamManager,
                        'Inbound Stream ----> ',
                        'setRemoteDescription complete, creating answer'
                    );
                    return this.inboundPeerConnection?.createAnswer();
                })
                .then((sessionDescription) => {
                    logInfo(
                        this.streamManager,
                        'Inbound Stream ----> ',
                        'createAnswer complete, setLocalDescription'
                    );
                    return this.inboundPeerConnection?.setLocalDescription(
                        sessionDescription
                    );
                })
                .then(() => {
                    logInfo(
                        this.streamManager,
                        'Inbound Stream ----> ',
                        '**** Adding remote early candidates *****',
                        this.inboundEarlyCandidates
                    );
                    this.inboundEarlyCandidates.forEach(
                        this.onReceiveSingleCandidate.bind(this)
                    );
                    logInfo(
                        this.streamManager,
                        'Inbound Stream ----> ',
                        'setLocalDescription complete, sendAnswer'
                    );
                    const answerPayload = {
                        apiKey: 'api/v1/streambridge/setAnswer',
                        peerId: this.inboundPeerId,
                        data: {
                            sessionDescription:
                                this.inboundPeerConnection?.localDescription,
                            peerId: this.inboundPeerId,
                        },
                    };
                    const jsonString = JSON.stringify(answerPayload);
                    this.streamManager.sendWebSocketMessage(jsonString);
                })
                .catch((e) => {
                    logError(
                        this.streamManager,
                        'Error during offer handling: ',
                        e
                    );
                });
        }
    }

    private getVstConfig(): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'Calling getVstConfig'
        );
        const jsonData = {
            apiKey: 'api/v1/streambridge/configuration',
            data: null,
            peerId: this.inboundPeerId,
        };
        const jsonString = JSON.stringify(jsonData);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private addIceCandidate(candidate: RTCIceCandidateInit): void {
        const jsonData = {
            apiKey: 'api/v1/streambridge/iceCandidate',
            peerId: this.inboundPeerId,
            data: candidate,
        };
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'calling /v1/iceCandidate with ',
            jsonData
        );
        const jsonString = JSON.stringify(jsonData);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private onIceCandidate(event: RTCPeerConnectionIceEvent): void {
        if (!event.candidate) {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'received null candidate, that means its the last candidate - client is chromium based'
            );
            return;
        }
        if (event.candidate.candidate.length === 0) {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'Received empty string for candidate - client is firefox'
            );
            return;
        }
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'received candidate inside onIceCandidate callback: ',
            event.candidate.candidate
        );
        if (event.candidate.type === 'srflx') {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'The STUN server is reachable for this candidate!'
            );
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                `Public IP Address is: ${event.candidate.address}`
            );
        }
        if (event.candidate.type === 'relay') {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'The TURN server is reachable for this candidate!'
            );
        }
        if (
            this.inboundPeerConnection &&
            this.inboundPeerConnection.currentRemoteDescription
        ) {
            this.addIceCandidate(event.candidate);
        } else {
            this.inboundEarlyCandidates.push(event.candidate);
        }
    }

    private rewriteSdp(
        sdp: RTCSessionDescriptionInit
    ): RTCSessionDescriptionInit {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'Rewriting SDP with bitrates'
        );
        if (
            this.inboundMaxBitrate &&
            this.inboundMinBitrate &&
            this.inboundStartBitrate
        ) {
            const sdpStringFind = 'a=fmtp:(.*) (.*)';
            const sdpStringReplace = `a=fmtp:$1 $2;x-google-max-bitrate=${this.inboundMaxBitrate};x-google-min-bitrate=${this.inboundMinBitrate};x-google-start-bitrate=${this.inboundStartBitrate}`;
            let newSDP = sdp.sdp?.toString();
            newSDP = newSDP?.replace(
                new RegExp(sdpStringFind, 'g'),
                sdpStringReplace
            );
            sdp.sdp = newSDP;
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'using modified SDP Answer: ',
                sdp
            );
        }
        return sdp;
    }

    private onReceiveCandidate(candidates: RTCIceCandidateInit[]): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            `Received candidates from VMS: ${JSON.stringify(candidates)}`
        );
        if (candidates) {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'Creating RTCIceCandidate from each received candidate..'
            );
            for (let i = 0; i < candidates.length; i += 1) {
                const candidate = candidates[i];
                logInfo(
                    this.streamManager,
                    'Inbound Stream ----> ',
                    `Adding ICE candidate - ${i} :${JSON.stringify(candidate)}`
                );
                this.inboundPeerConnection
                    ?.addIceCandidate(candidate)
                    .then(() => {
                        logInfo(
                            this.streamManager,
                            'Inbound Stream ----> ',
                            `addIceCandidate OK - ${i}`
                        );
                    })
                    .catch((error) => {
                        logInfo(
                            this.streamManager,
                            'Inbound Stream ----> ',
                            `addIceCandidate error - ${i}`,
                            error
                        );
                    });
            }
        }
    }

    private onIceConnectionStateChange(): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'ice connection state change: ',
            this.inboundPeerConnection?.iceConnectionState || ''
        );
        if (this.inboundPeerConnection?.iceConnectionState === 'new') {
            // Candidates will come async over websocket
        }
        if (this.inboundPeerConnection?.iceConnectionState === 'connected') {
            const statusPayload = {
                apiKey: '/event/streambridge/ext/peerconnection/status',
                peerId: this.inboundPeerId,
                data: { status: 'connected', peerId: this.inboundPeerId },
            };
            this.isConnected = true;
            const jsonString = JSON.stringify(statusPayload);
            this.streamManager.sendWebSocketMessage(jsonString);
            this.streamManager.onInboundStreamConnection();
            this.streamManager.setInboundStreamConnectionStatus(true);
        }
        if (this.inboundPeerConnection?.iceConnectionState === 'disconnected') {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'ICE connection failed, restart at this point'
            );
            const statusPayload = {
                apiKey: '/event/streambridge/ext/peerconnection/status',
                peerId: this.inboundPeerId,
                data: { status: 'disconnected', peerId: this.inboundPeerId },
            };
            const jsonString = JSON.stringify(statusPayload);
            this.streamManager.sendWebSocketMessage(jsonString);
            this.streamManager.handleAppCleanup();
        }
        if (this.inboundPeerConnection?.iceConnectionState === 'failed') {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'ICE connection failed, restart at this point'
            );
            this.streamManager.handleAppCleanup();
        }
    }

    private onIceCandidateError(e: any): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'onIceCandidateError: ',
            e
        );
        if (e.errorCode !== 701) {
            logError(
                this.streamManager,
                `${e.errorText} error code ${e.errorCode} and url ${e.url}`,
                'onIceCandidateError'
            );
        }
        if (e.errorCode === 701) {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'error code is 701 that means DNS failed for ipv6, harmless error'
            );
        }
    }

    private onIceGatheringStateChange(): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'gathering state change: ',
            this.inboundPeerConnection?.iceGatheringState || ''
        );
    }

    private onSignalingStateChange(): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'signaling state change: ',
            this.inboundPeerConnection?.signalingState || ''
        );
    }

    private onConnectionStateChange(): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'connection state change: ',
            this.inboundPeerConnection?.connectionState || ''
        );
        if (this.inboundPeerConnection?.connectionState === 'disconnected') {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'Lost peer connection...'
            );
        }
    }

    private setRemoteDescription(
        sessionDescriptionAnswer: RTCSessionDescriptionInit
    ): void {
        if (this.inboundPeerConnection) {
            this.inboundPeerConnection
                .setRemoteDescription(
                    new RTCSessionDescription(sessionDescriptionAnswer)
                )
                .then(() => {
                    this.inboundEarlyCandidates.forEach(
                        this.addIceCandidate.bind(this)
                    );
                })
                .catch((e) => {
                    logError(
                        this.streamManager,
                        'Failed to set remote description',
                        e
                    );
                });
        } else {
            logError(
                this.streamManager,
                'Failed to set remote description, no peer connection found'
            );
        }
    }

    private sendSessionDescriptionToVst(
        sessionDescription: RTCSessionDescriptionInit
    ): void {
        const sessionDescriptionPayload = {
            apiKey: 'api/v1/streambridge/stream/start',
            peerId: this.inboundPeerId,
            data: {
                clientIpAddr: this.publicIPAddress,
                peerId: this.inboundPeerId,
                isAvatar: true,
                options: { quality: 'auto', rtptransport: 'udp', timeout: 60 },
                sessionDescription,
            },
        };
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'Payload of stream start: ',
            sessionDescriptionPayload
        );
        const jsonString = JSON.stringify(sessionDescriptionPayload);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private createOffer(): void {
        logInfo(this.streamManager, 'Inbound Stream ----> ', 'Creating Offer');
        this.inboundPeerConnection
            ?.createOffer(OFFER_OPTIONS)
            .then((sessionDescription) => {
                logInfo(
                    this.streamManager,
                    'Inbound Stream ----> ',
                    'session Description with bitrates',
                    sessionDescription
                );
                this.inboundPeerConnection?.setLocalDescription(
                    sessionDescription
                );
                sessionDescription = this.rewriteSdp(sessionDescription);
                return sessionDescription;
            })
            .then((sessionDescription) => {
                this.sendSessionDescriptionToVst(sessionDescription);
            })
            .catch((error) => {
                logError(this.streamManager, 'Failed to create offer', error);
            });
    }

    private createRTCPeerConnectionForInboundStream(
        iceServerList: IceServer[] | null,
        flag: boolean = true
    ): void {
        const rtcConfiguration: RTCConfiguration = {
            iceServers:
                iceServerList && iceServerList.length > 0 ? iceServerList : [],
        };
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'createRTCPeerConnectionForInboundStream'
        );
        try {
            this.inboundPeerConnection = new RTCPeerConnection(
                rtcConfiguration
            );
            this.inboundPeerConnection.onicecandidate =
                this.onIceCandidate.bind(this);
            this.inboundPeerConnection.oniceconnectionstatechange =
                this.onIceConnectionStateChange.bind(this);
            this.inboundPeerConnection.onicecandidateerror =
                this.onIceCandidateError.bind(this);
            this.inboundPeerConnection.onicegatheringstatechange =
                this.onIceGatheringStateChange.bind(this);
            this.inboundPeerConnection.onsignalingstatechange =
                this.onSignalingStateChange.bind(this);
            this.inboundPeerConnection.onconnectionstatechange =
                this.onConnectionStateChange.bind(this);
            this.inboundPeerConnection.ontrack = this.onTrack.bind(this);
        } catch (error) {
            logError(
                this.streamManager,
                'Inbound Stream ----> ',
                `Failed to create RTC peer connection.`,
                error
            );
        }
        if (flag) {
            this.addDataListener();
            this.createOffer();
        }
    }

    private onTrack(event: RTCTrackEvent) {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'on track called!'
        );
        const [stream] = event.streams;
        const videoElement = document.getElementById(
            this.streamManager.getConfig().avatarVideoElementId
        ) as HTMLVideoElement;

        if (videoElement) {
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'stream received',
                stream
            );

            // Add video tracks to the mediaStream
            const videoTracks = stream.getVideoTracks();
            if (videoTracks.length > 0) {
                logInfo(
                    this.streamManager,
                    'Inbound Stream ----> ',
                    'Adding video track'
                );
                videoTracks.forEach((track) =>
                    this.mediaStream?.addTrack(track)
                );
            }

            // Add audio tracks to the mediaStream
            const audioTracks = stream.getAudioTracks();
            if (audioTracks.length > 0) {
                logInfo(
                    this.streamManager,
                    'Inbound Stream ----> ',
                    'Adding audio track'
                );
                audioTracks.forEach((track) =>
                    this.mediaStream?.addTrack(track)
                );
            }

            // Update the srcObject property of the video element with the combined mediaStream
            videoElement.srcObject = this.mediaStream;

            // Attempt to play the video element
            const playPromise = videoElement.play();
            if (playPromise !== undefined) {
                playPromise
                    .then(() => {
                        logInfo(
                            this.streamManager,
                            'Inbound Stream ----> ',
                            'Autoplay with audio successful'
                        );
                        videoElement.muted = false;
                    })
                    .catch(() => {
                        // Handle autoplay failure
                        logError(
                            this.streamManager,
                            'Inbound Stream ----> ',
                            'Autoplay with audio failed'
                        );
                        videoElement.muted = true; // Mute and try again
                        videoElement.play();
                    });
            }
        } else {
            logError(this.streamManager, 'Video element not found');
        }
    }

    private getIceServers(): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'Calling getIceServers',
            this.inboundPeerId
        );
        const jsonData = {
            apiKey: 'api/v1/streambridge/iceServers',
            peerId: this.inboundPeerId,
            data: { peerId: this.inboundPeerId },
        };
        const jsonString = JSON.stringify(jsonData);
        this.streamManager.sendWebSocketMessage(jsonString);
    }

    private getBitrateSettings(config: any): BitrateSettings {
        try {
            const resolution = config.WebrtcOutDefaultResolution;
            const height = parseInt(resolution.split('x')[1], 10);
            let settings = null;
            switch (height) {
                case 480:
                    settings =
                        config.webrtc_video_quality_tunning.resolution_480;
                    break;
                case 720:
                    settings =
                        config.webrtc_video_quality_tunning.resolution_720;
                    break;
                case 1080:
                    settings =
                        config.webrtc_video_quality_tunning.resolution_1080;
                    break;
                case 1440:
                    settings =
                        config.webrtc_video_quality_tunning.resolution_1440;
                    break;
                case 2160:
                    settings =
                        config.webrtc_video_quality_tunning.resolution_2160;
                    break;
                default:
                    throw new Error(`Unsupported resolution height: ${height}`);
            }
            const [bitrate_min, bitrate_max] = settings.bitrate_range;
            return {
                bitrate_min,
                bitrate_max,
                bitrate_start: settings.bitrate_start,
            };
        } catch (error) {
            logInfo(this.streamManager, 'Failed to do get bitrates');
            return {
                bitrate_min: null,
                bitrate_max: null,
                bitrate_start: null,
            };
        }
    }

    public doCleanup(): void {
        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'Cleanup media streams'
        );
        if (this.connectionWatchDog) {
            clearTimeout(this.connectionWatchDog);
        }
        if (this.mediaStream) {
            this.mediaStream.getTracks().forEach((track) => track.stop());
            this.mediaStream = null;
        }

        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'Cleanup inbound peer connection'
        );
        if (this.inboundPeerConnection) {
            const jsonPayload = {
                apiKey: 'api/v1/streambridge/stream/stop',
                peerId: this.inboundPeerId,
                data: { peerId: this.inboundPeerId },
            };
            const jsonString = JSON.stringify(jsonPayload);
            this.streamManager.sendWebSocketMessage(jsonString);
            this.inboundPeerConnection.close();
            this.inboundPeerConnection = null;
            logInfo(
                this.streamManager,
                'Inbound Stream ----> ',
                'Set inbound peer connection to null'
            );
        }

        logInfo(
            this.streamManager,
            'Inbound Stream ----> ',
            'Cleanup video element'
        );
        const videoElement = document.getElementById(
            this.streamManager.getConfig().avatarVideoElementId
        ) as HTMLVideoElement | null;
        if (videoElement) {
            videoElement.srcObject = null;
            videoElement.load();
        }
        this.inboundEarlyCandidates = [];
        logInfo(this.streamManager, 'Inbound Stream ----> ', 'cleanup done');
    }

    private initiateWebrtcConnection(): void {
        this.getVstConfig();
        this.mediaStream = new MediaStream();
    }
}
